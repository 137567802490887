export default {
    name: 'TrainOrderRulePopup',
    data() {
        return {
            activeName: '1'
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        active: {
            type: String,
            default: '1'
        }
    },
    components: {

    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        ruleShow(newVal) {
            this.activeName = this.active;
        }
    },
    computed: {
        ruleShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
    },
    filters: {},
    methods: {}
}
