// 【399】admin-代客下单申请火车票退票费用计算
// POST /consumer/admin/trains/order/applyForRefundCost
// 接口ID：50668430
// 接口地址：https://www.apifox.cn/link/project/458418/apis/api-50668430

const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_trains_order_applyForRefundCost = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/applyForRefundCost',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_applyForRefundCost;